<template>
  <div class="hello">
    <h1>Collection User !</h1>
    <Autosaveinfo v-if="slct_value != ''" :data="autoSave" @save="update()" />
    <section id="collect_user">
      <section class="inline" id="select">
        <div>
          <select
            name=""
            id=""
            v-model="slct_value"
            @change="onChangeSelect($event)"
          >
            <option value="" selected disabled hidden>Choisir un User</option>
            <option v-for="usr in users" :value="usr._id" :key="usr._id">
              {{ usr.usr_lastname }} {{ usr.usr_firstname }}
              <!--| {{ usr.usr_ent }}-->
            </option>
          </select>
        </div>

        <div v-if="slct_value == ''">OU</div>
        <div>
          <button v-if="slct_value == ''" @click="add">Ajouter</button>
          <button v-if="slct_value != ''" @click="reset_slct">Nouveau</button>
          <button v-if="slct_value != ''" @click="remove">Supprimer</button>
        </div>
      </section>
      <div></div>
      <section id="block_field">
        <section class="inline">
          <div class="subBlock_field">
            <div class="inline">
              <label :class="{ error: errors.usr_lastname.color }" for=""
                >Nom :</label
              >
              <input v-model="temp.usr_lastname" type="text" />
            </div>

            <div v-if="errors.usr_lastname.msg">
              <span style="color: red">Ne peut contenir que des lettres</span>
            </div>
          </div>
          <div class="subBlock_field">
            <div class="inline">
              <label :class="{ error: errors.usr_firstname.color }" for=""
                >Prénom :</label
              >
              <input v-model="temp.usr_firstname" type="text" />
            </div>

            <div v-if="errors.usr_firstname.msg">
              <span style="color: red">Ne peut contenir que des lettres</span>
            </div>
          </div>

          <div class="subBlock_field">
            <div class="inline">
              <label for="">Role :</label>
              <select class="select_role" v-model="temp.usr_role" name="" id="">
                <option value="0">Admin</option>
                <option value="1">User</option>
              </select>
            </div>
          </div>
        </section>
        <div></div>
        <section class="inline">
          <div class="subBlock_field">
            <div class="inline">
              <label :class="{ error: errors.usr_email.color }" for=""
                >Email :</label
              >
              <input v-model="temp.usr_email" type="text" />
            </div>
            <div v-if="errors.usr_email.msg">
              <span style="color: red">Format email incorrect</span>
            </div>
          </div>

          <div class="subBlock_field">
            <div class="inline">
              <label for="" :class="{ error: errors.usr_company.color }"
                >Entreprise :</label
              >
              <input v-model="temp.usr_company" type="text" />
            </div>
          </div>

          <div class="subBlock_field"></div>
        </section>
        <section>
          <div>
            <span v-if="err">{{ err }}</span>
          </div>
        </section>
      </section>
    </section>
  </div>
</template>

<script>
import collect_api from "../../../api/collect";
import verifGlobal from "../../../lib/verifGlobal";

import $ from "jquery";
//Components
import Autosaveinfo from "../../../components/Object/AutoSaveInfo";
export default {
  name: "Admin.user",
  components: { Autosaveinfo },
  data() {
    return {
      event_submit: false,
      users: {},
      temp: {
        usr_role: 1,
        usr_lastname: "",
        usr_firstname: "",
        usr_email: "",
        usr_company: "",
      },
      slct_value: "",
      err: "",
      errors: {
        usr_lastname: {},
        usr_firstname: {},
        usr_email: {},
        usr_company: {},
        global: false,
      },
      autoSave: {
        status: 1,
        lastModification: Date,
        lastSave: "",
      },
    };
  },
  mounted() {
    collect_api.get("/users").then((res) => {
      this.users = res;
    });

    //AutoSave Interval
    setInterval(() => {
      if (this.slct_value != "") {
        var currentTime = new Date();
        var delta = (currentTime - this.autoSave.lastModification) / 1000;
        const saveDelay = 2;
        if (saveDelay == Math.round(delta)) {
          //SAVE
          console.debug("Saving data...");
          this.update();
          this.autoSave.lastSave = (() => {
            var date = new Date();
            return date.getHours() + ":" + date.getMinutes();
          })();
          this.autoSave.status = 1;
        }
      }
    }, 1000);
    $("textarea, input[type=text] ").on("keyup", () => {
      this.autoSave.status = 0;
      this.autoSave.lastModification = new Date();
      
    });
    $(".select_role").on("change", () => {
      this.autoSave.status = 0;
      this.autoSave.lastModification = new Date();
     
    });
  },
  watch: {
    temp: {
      handler(user) {
        if (this.errors.global) {
          for (let field in user) {
            if (
              !verifGlobal.isEmpty(user[field]) &&
              this.errors[field] != undefined
            ) {
              if (this.errors[field].color) this.errors[field].color = false;
            }
          }
        }
      },
      deep: true,
    },
    "temp.usr_firstname": function (value) {
      if (value != undefined && value != "") {
        let verif = verifGlobal.notAlphabetical(value);
        this.errors.usr_firstname.msg = verif;
        this.errors.usr_firstname.color = verif;
      } else {
        this.errors.usr_firstname.msg = false;
        this.errors.usr_firstname.color = false;
      }
    },
    "temp.usr_lastname": function (value) {
      if (value != undefined && value != "") {
        let verif = verifGlobal.notAlphabetical(value);
        this.errors.usr_lastname.msg = verif;
        this.errors.usr_lastname.color = verif;
      } else {
        this.errors.usr_lastname.msg = false;
        this.errors.usr_lastname.color = false;
      }
    },
    "temp.usr_email": function (email) {
      if (email != undefined && email != "") {
        let verif = !email.includes("@");
        this.errors.usr_email.msg = verif;
        this.errors.usr_email.color = verif;
      } else {
        this.errors.usr_email.msg = false;
        this.errors.usr_email.color = false;
      }
    },
  },
  methods: {
    onChangeSelect(event) {
      if (event.target.value != "") {
        collect_api.get("/users/" + event.target.value).then((user) => {
          this.temp = user;
        });
      } else {
        this.temp = {};
      }
      /*if (event.target.value != "") {
        this.temp = this.users.filter((u) => u._id == event.target.value)[0];
      } else {
        this.temp = {};
      }*/
    },
    remove() {
      collect_api.delete("users", this.temp).then(() => {
        this.users = this.users.filter((u) => u._id != this.temp._id);
        this.reset_slct();
      });
    },
    update() {
      this.errors.global = false;

      for (let field in this.temp) {
        if (
          this.errors[field] != undefined &&
          verifGlobal.isEmpty(this.temp[field])
        ) {
          this.errors[field].color = true;
        }
      }
      for (let field in this.errors) {
        if (this.errors[field].color) {
          this.errors.global = true;
        }
      }
      if (!this.errors.global) {
        collect_api.update("users", this.temp).then((res) => {
          let index = this.users.findIndex((obj) => obj._id == this.temp._id);
          this.users[index] = this.temp;

          this.Toaster.success(this.$t('Toaster.success.update'))
        });
      } else {
        this.errors.global = true;
      }
    },
    add() {
      this.errors.global = false;

      for (let field in this.temp) {
        if (
          this.errors[field] != undefined &&
          verifGlobal.isEmpty(this.temp[field])
        ) {
          this.errors[field].color = true;
        }
      }

      for (let field in this.errors) {
        if (this.errors[field].color) {
          this.errors.global = true;
        }
      }

      if (!this.errors.global) {
        collect_api.add("users", this.temp).then((res) => {
          this.Toaster.success(this.$t('Toaster.success.add'))
          this.users.push(res);
          this.reset_slct();
        });
      } else {
        this.errors.global = true;
        this.Toaster.error(this.$t('Toaster.error.requiredFields'))
      }
    },
    reset_slct() {
      this.temp = {
        usr_role: 1,
        usr_lastname: "",
        usr_firstname: "",
        usr_email: "",
        usr_company: "",
      };
      this.slct_value = "";
      this.err = "";

      this.errors = {
        usr_lastname: {},
        usr_firstname: {},
        usr_email: {},
        usr_company: {},
        usr_role: {},
        global: false,
      };
    },
  },
};
</script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#collect_user {
  display: block;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  background-color: #e4d8b0;
  padding: 10px;
  padding-bottom: 5px;
  border-radius: 25px;

  font-size: 1.1vw;
}

#block_field {
  display: block;
  width: 85%;
  margin-left: 2%;
}
</style>
<style src="@/views/Admin/subViews/style.css" scoped></style>
